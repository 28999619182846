/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountModel {
  accountId?: string | null;
  status?: AccountStatusEnum;
  profiles?: AccountProfileModel[] | null;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
}

export interface AccountModelPaginationResult {
  pagination?: PaginationDetail;
  result?: AccountModel[] | null;
}

export interface AccountModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: AccountModelPaginationResult;
  message?: string | null;
}

export interface AccountModelResultHandler {
  succeeded?: boolean;
  data?: AccountModel;
  message?: string | null;
}

export interface AccountProfileModel {
  account?: AccountModel;
  profile?: ProfileDetailModel;
  owner?: boolean;
}

export enum AccountStatusEnum {
  Created = 'Created',
  NoCredit = 'NoCredit',
  Active = 'Active',
}

export interface AccountStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: AccountStatusEnum[] | null;
  message?: string | null;
}

export interface AutocompleteCriteria {
  name?: string | null;
  companyIds?: string[] | null;
}

export interface ChildModel {
  fullName?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  gender?: GenderEnum;
}

export interface CompanyDetailModel {
  companyId?: string | null;
  status?: CompanyStatusEnum;
  name?: string | null;
  legalName?: string | null;
  document?: DocumentModel;

  /** @format date-time */
  contractEnd?: string | null;

  /** @format int32 */
  profilesCount?: number;

  /** @format int32 */
  contractAppointmentLimit?: number | null;
  contractStatus?: ContractStatusEnum;
  responsibleName?: string | null;
  responsibleEmail?: string | null;
  responsiblePhone?: string | null;
  responsiblePosition?: string | null;

  /** @format date-time */
  updated?: string | null;
}

export interface CompanyDetailModelResultHandler {
  succeeded?: boolean;
  data?: CompanyDetailModel;
  message?: string | null;
}

export interface CompanyModel {
  companyId?: string | null;
  status?: CompanyStatusEnum;
  name?: string | null;
  legalName?: string | null;
  document?: DocumentModel;
  responsibleName?: string | null;
  responsibleDocument?: DocumentModel;
  responsiblePhone?: PhoneModel;
  responsibleEmail?: EmailModel;
  responsiblePosition?: string | null;
  contracts?: ContractModel[] | null;
  profiles?: ProfileDetailModel[] | null;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
}

export interface CompanySaveModel {
  companyId?: string | null;
  name?: string | null;
  legalName?: string | null;
  document?: DocumentModel;
  contractStatus?: ContractStatusEnum;

  /** @format date-time */
  contractEnd?: string | null;

  /** @format int32 */
  contractAppointmentLimit?: number | null;
  responsibleName?: string | null;
  responsibleEmail?: string | null;
  responsiblePhone?: string | null;
  responsiblePosition?: string | null;
}

export interface CompanySearchModel {
  companyId?: string | null;
  name?: string | null;
  status?: CompanyStatusEnum;
  documentNumber?: string | null;

  /** @format date-time */
  contractEnd?: string | null;

  /** @format int32 */
  subscribersCount?: number;

  /** @format date-time */
  updated?: string | null;
}

export interface CompanySearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: CompanySearchModel[] | null;
}

export interface CompanySearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: CompanySearchModelPaginationResult;
  message?: string | null;
}

export enum CompanyStatusEnum {
  Registered = 'Registered',
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface CompanyStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: CompanyStatusEnum[] | null;
  message?: string | null;
}

export interface ContentDetailModel {
  contentId?: string | null;
  status?: ContentStatusEnum;
  type?: ContentTypeEnum;
  title?: string | null;
  headline?: string | null;
  description?: string | null;
  transcription?: string | null;
  publicAddress?: string | null;
  theme?: KeyValueModel;
  specialist?: KeyValueModel;
  phases?: KeyValueModel[] | null;
  personas?: KeyValueModel[] | null;
  audioMedia?: MediaModel;
  videoMedia?: MediaModel;
  highlightMedia?: MediaModel;
  coverMedia?: MediaModel;
  deepLinkingAddress?: string | null;
}

export interface ContentDetailModelResultHandler {
  succeeded?: boolean;
  data?: ContentDetailModel;
  message?: string | null;
}

export interface ContentSaveModel {
  contentId?: string | null;
  status?: ContentStatusEnum;
  type?: ContentTypeEnum;
  title?: string | null;
  headline?: string | null;
  description?: string | null;
  transcription?: string | null;
  themeId?: string | null;
  specialistId?: string | null;
  phaseIds?: string[] | null;
  personaIds?: string[] | null;
  audioMediaId?: string | null;
  videoMediaId?: string | null;
  highlightMediaId?: string | null;
  coverMediaId?: string | null;
}

export interface ContentSearchModel {
  contentId?: string | null;
  title?: string | null;
  type?: ContentTypeEnum;
  theme?: string | null;

  /** @format int32 */
  phaseCount?: number;
  specialist?: string | null;

  /** @format date-time */
  updated?: string | null;
  deepLinkingAddress?: string | null;
}

export interface ContentSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ContentSearchModel[] | null;
}

export interface ContentSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ContentSearchModelPaginationResult;
  message?: string | null;
}

export enum ContentStatusEnum {
  Draft = 'Draft',
  Published = 'Published',
}

export interface ContentStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ContentStatusEnum[] | null;
  message?: string | null;
}

export enum ContentTypeEnum {
  Audio = 'Audio',
  Video = 'Video',
  Article = 'Article',
}

export interface ContentTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ContentTypeEnum[] | null;
  message?: string | null;
}

export interface ContractModel {
  contractId?: string | null;
  type?: ContractTypeEnum;
  status?: ContractStatusEnum;
  ownerCompany?: CompanyModel;
  ownerProfile?: ProfileDetailModel;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string | null;

  /** @format int32 */
  profileLimit?: number | null;

  /** @format int32 */
  appointmentLimit?: number | null;

  /** @format int32 */
  billingCycleMonths?: number | null;

  /** @format double */
  paymentSharingCompany?: number | null;

  /** @format double */
  paymentSharingProfile?: number | null;
  contractProfiles?: ContractProfileModel[] | null;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
}

export interface ContractModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ContractModel[] | null;
}

export interface ContractModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ContractModelPaginationResult;
  message?: string | null;
}

export interface ContractModelResultHandler {
  succeeded?: boolean;
  data?: ContractModel;
  message?: string | null;
}

export interface ContractProfileDetailModel {
  contractProfileId?: string | null;
  contract?: ContractModel;
  status?: ContractProfileStatusEnum;
  inviteEmail?: string | null;
  inviteCode?: string | null;

  /** @format date-time */
  inviteExpiration?: string | null;
  profile?: ProfileDetailModel;

  /** @format date-time */
  updated?: string | null;
}

export interface ContractProfileDetailModelResultHandler {
  succeeded?: boolean;
  data?: ContractProfileDetailModel;
  message?: string | null;
}

export interface ContractProfileModel {
  contractProfileId?: string | null;
  contract?: ContractModel;
  status?: ContractProfileStatusEnum;
  inviteEmail?: string | null;
  inviteCode?: string | null;

  /** @format date-time */
  inviteExpiration?: string | null;
  profile?: ProfileDetailModel;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
}

export interface ContractProfileSaveModel {
  contractProfileId?: string | null;
  contractId?: string | null;
  status?: ContractProfileStatusEnum;
  inviteEmail?: string | null;
  inviteCode?: string | null;

  /** @format date-time */
  inviteExpiration?: string | null;
  profileId?: string | null;
}

export interface ContractProfileSearchModel {
  contractProfileId?: string | null;
  profileFullName?: string | null;
  profileEmail?: string | null;
  companyName?: string | null;

  /** @format int32 */
  contractSharedCount?: number;

  /** @format int32 */
  profileChildrenCount?: number;

  /** @format int32 */
  contractInviteCount?: number;

  /** @format date-time */
  subscribed?: string;

  /** @format date-time */
  lastAccess?: string | null;
}

export interface ContractProfileSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ContractProfileSearchModel[] | null;
}

export interface ContractProfileSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ContractProfileSearchModelPaginationResult;
  message?: string | null;
}

export enum ContractProfileStatusEnum {
  Invited = 'Invited',
  WaitingValidation = 'WaitingValidation',
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface ContractProfileStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ContractProfileStatusEnum[] | null;
  message?: string | null;
}

export enum ContractStatusEnum {
  Created = 'Created',
  WaitingSignature = 'WaitingSignature',
  Active = 'Active',
  Inactive = 'Inactive',
  Suspended = 'Suspended',
  Trial = 'Trial',
}

export interface ContractStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ContractStatusEnum[] | null;
  message?: string | null;
}

export enum ContractTypeEnum {
  Business = 'Business',
  Consumer = 'Consumer',
}

export interface ContractTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ContractTypeEnum[] | null;
  message?: string | null;
}

export enum CurrencyEnum {
  BRL = 'BRL',
}

export interface CurrencyEnumArrayResultHandler {
  succeeded?: boolean;
  data?: CurrencyEnum[] | null;
  message?: string | null;
}

export interface DeepLinkingModel {
  typeName?: string | null;
  url?: string | null;
}

export interface DeepLinkingModelArrayResultHandler {
  succeeded?: boolean;
  data?: DeepLinkingModel[] | null;
  message?: string | null;
}

export interface DocumentModel {
  documentId?: string | null;
  formattedNumber?: string | null;
  rawNumber?: string | null;

  /** @format date-time */
  issueDate?: string | null;

  /** @format date-time */
  expirationDate?: string | null;
  countryCode?: string | null;
  type?: DocumentTypeEnum;
}

export enum DocumentTypeEnum {
  Passport = 'Passport',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  RG = 'RG',
  CUIT = 'CUIT',
  EIN = 'EIN',
  SSN = 'SSN',
  CRM = 'CRM',
}

export interface EmailModel {
  emailId?: string | null;
  address?: string | null;
}

export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export interface GenderEnumArrayResultHandler {
  succeeded?: boolean;
  data?: GenderEnum[] | null;
  message?: string | null;
}

export interface InviteSearchModel {
  contractProfileId?: string | null;
  name?: string | null;
  email?: string | null;
  companyName?: string | null;

  /** @format int32 */
  profileCount?: number;

  /** @format int32 */
  profileChildrenCount?: number;

  /** @format int32 */
  invitedCount?: number;

  /** @format date-time */
  updated?: string | null;

  /** @format date-time */
  lastAccess?: string | null;
}

export interface InviteSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: InviteSearchModel[] | null;
}

export interface InviteSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: InviteSearchModelPaginationResult;
  message?: string | null;
}

export interface KeyValueModel {
  key?: string | null;
  value?: string | null;
}

export interface KeyValueModelArrayResultHandler {
  succeeded?: boolean;
  data?: KeyValueModel[] | null;
  message?: string | null;
}

export interface MediaModel {
  mediaId?: string | null;
  type?: MediaTypeEnum;
  url?: string | null;
}

export interface MediaModelPaginationResult {
  pagination?: PaginationDetail;
  result?: MediaModel[] | null;
}

export interface MediaModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: MediaModelPaginationResult;
  message?: string | null;
}

export interface MediaModelResultHandler {
  succeeded?: boolean;
  data?: MediaModel;
  message?: string | null;
}

export enum MediaTypeEnum {
  Audio = 'Audio',
  Video = 'Video',
  Image = 'Image',
  ImageCover = 'ImageCover',
  ImageHighlight = 'ImageHighlight',
  ImageMale = 'ImageMale',
  ImageFemale = 'ImageFemale',
  ImageAvatar = 'ImageAvatar',
  ImagePresentation = 'ImagePresentation',
}

export interface MediaTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: MediaTypeEnum[] | null;
  message?: string | null;
}

export interface NotificationAllModel {
  notificationId?: string | null;
  title?: string | null;
  body?: string | null;
  profiles?: NotificationProfileAllModel[] | null;
}

export interface NotificationAllModelListResultHandler {
  succeeded?: boolean;
  data?: NotificationAllModel[] | null;
  message?: string | null;
}

export interface NotificationDetailModel {
  notificationId?: string | null;
  type?: NotificationTypeEnum;
  status?: NotificationStatusEnum;

  /** @format date-time */
  scheduledFor?: string | null;
  title?: string | null;
  body?: string | null;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
  content?: ContentDetailModel;
  segmentation?: NotificationSegmentationModel;
  profiles?: NotificationProfileModel[] | null;
  profileIds?: string[] | null;
}

export interface NotificationDetailModelResultHandler {
  succeeded?: boolean;
  data?: NotificationDetailModel;
  message?: string | null;
}

export interface NotificationProfileAllModel {
  /** @format int32 */
  notificationId?: number;

  /** @format int32 */
  profileId?: number;
  expoPushToken?: string | null;
}

export interface NotificationProfileModel {
  /** @format int32 */
  notificationId?: number;

  /** @format int32 */
  profileId?: number;
}

export interface NotificationSaveModel {
  notificationId?: string | null;
  type?: NotificationTypeEnum;
  status?: NotificationStatusEnum;

  /** @format date-time */
  scheduledFor?: string | null;
  title?: string | null;
  body?: string | null;
  profileIds?: string[] | null;
  profiles?: NotificationProfileModel[] | null;
  segmentation?: NotificationSegmentationModel;
  contentId?: string | null;
}

export interface NotificationSearchModel {
  notificationId?: string | null;
  type?: NotificationTypeEnum;
  status?: NotificationStatusEnum;

  /** @format date-time */
  scheduledFor?: string | null;
  title?: string | null;
  body?: string | null;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;

  /** @format int32 */
  segmentationCount?: number;
}

export interface NotificationSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: NotificationSearchModel[] | null;
}

export interface NotificationSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: NotificationSearchModelPaginationResult;
  message?: string | null;
}

export interface NotificationSegmentationModel {
  notificationSegmentationId?: string | null;

  /** @format int32 */
  totalProfiles?: number;
  companyIds?: string[] | null;
  gender?: GenderEnum;
  phaseTypeId?: string | null;

  /** @format int32 */
  ageStart?: number | null;

  /** @format int32 */
  ageEnd?: number | null;
  hasProfile?: boolean | null;
  hasChildren?: boolean | null;

  /** @format int32 */
  lastAccesses?: number | null;
}

export enum NotificationStatusEnum {
  Draft = 'Draft',
  Review = 'Review',
  WaitingApproval = 'WaitingApproval',
  ReadyToSend = 'ReadyToSend',
  Sent = 'Sent',
  Canceled = 'Canceled',
}

export interface NotificationStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: NotificationStatusEnum[] | null;
  message?: string | null;
}

export enum NotificationTypeEnum {
  Email = 'Email',
  Push = 'Push',
  SMS = 'SMS',
}

export interface NotificationTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: NotificationTypeEnum[] | null;
  message?: string | null;
}

export interface PaginationDetail {
  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;

  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  totalPages?: number;
}

export enum PaginationDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export interface PersonaModel {
  personaId?: string | null;
  name?: string | null;
}

export interface PersonaModelPaginationResult {
  pagination?: PaginationDetail;
  result?: PersonaModel[] | null;
}

export interface PersonaModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: PersonaModelPaginationResult;
  message?: string | null;
}

export interface PersonaModelResultHandler {
  succeeded?: boolean;
  data?: PersonaModel;
  message?: string | null;
}

export interface PhaseDetailModel {
  phaseId?: string | null;
  type?: PhaseTypeModel;
  name?: string | null;

  /** @format int32 */
  weeks?: number | null;

  /** @format int32 */
  months?: number | null;

  /** @format int32 */
  years?: number | null;
}

export interface PhaseDetailModelResultHandler {
  succeeded?: boolean;
  data?: PhaseDetailModel;
  message?: string | null;
}

export interface PhaseSaveModel {
  phaseId?: string | null;
  type?: PhaseTypeModel;
  name?: string | null;

  /** @format int32 */
  weeks?: number | null;

  /** @format int32 */
  months?: number | null;

  /** @format int32 */
  years?: number | null;
}

export interface PhaseSearchModel {
  phaseId?: string | null;
  name?: string | null;
  type?: PhaseTypeModel;

  /** @format date-time */
  updated?: string | null;
}

export interface PhaseSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: PhaseSearchModel[] | null;
}

export interface PhaseSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: PhaseSearchModelPaginationResult;
  message?: string | null;
}

export interface PhaseTypeModel {
  id?: string | null;
  name?: string | null;
}

export interface PhaseTypeModelArrayResultHandler {
  succeeded?: boolean;
  data?: PhaseTypeModel[] | null;
  message?: string | null;
}

export enum PhoneCountryCodeEnum {
  UN001 = 'UN001',
  AD = 'AD',
  AE = 'AE',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  BB = 'BB',
  BR = 'BR',
  BS = 'BS',
  BY = 'BY',
  CA = 'CA',
  CH = 'CH',
  CL = 'CL',
  CN = 'CN',
  CS = 'CS',
  CX = 'CX',
  DE = 'DE',
  FR = 'FR',
  GB = 'GB',
  HU = 'HU',
  IT = 'IT',
  JP = 'JP',
  KR = 'KR',
  MX = 'MX',
  NZ = 'NZ',
  PG = 'PG',
  PL = 'PL',
  RE = 'RE',
  RU = 'RU',
  SE = 'SE',
  SG = 'SG',
  US = 'US',
  UZ = 'UZ',
  YT = 'YT',
  ZW = 'ZW',
  ZZ = 'ZZ',
}

export interface PhoneModel {
  phoneId?: string | null;
  formattedNumber?: string | null;
  rawNumber?: string | null;
  countryCode?: PhoneCountryCodeEnum;
}

export interface PregnancyModel {
  name?: string | null;
  gender?: GenderEnum;

  /** @format date-time */
  estimatedDeliveryDate?: string | null;

  /** @format int32 */
  currentWeekPeriod?: number | null;
  twin?: boolean;
}

export interface ProfileDetailModel {
  profileId?: string | null;
  status?: ProfileStatusEnum;
  name?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  gender?: GenderEnum;
  company?: KeyValueModel;
  phone?: string | null;
  email?: string | null;
  holder?: ProfileDetailModel;
  dependents?: ProfileDetailModel[] | null;
  children?: ChildModel[] | null;
  pregnancy?: PregnancyModel;

  /** @format date-time */
  lastAccess?: string | null;
  planning?: boolean;
  familyRole?: string | null;
  personas?: string | null;
  planCareURL?: string | null;
  phaseTypes?: string | null;
  inactivation?: string | null;
  inactivationReason?: string | null;
}

export interface ProfileDetailModelResultHandler {
  succeeded?: boolean;
  data?: ProfileDetailModel;
  message?: string | null;
}

export interface ProfileImportCriteria {
  companyId?: string | null;
  mediaId?: string | null;
  readonly?: boolean;
  validate?: boolean;
}

export interface ProfileImportDetailModel {
  success?: boolean;

  /** @format int32 */
  status?: number;
  errorMessage?: string | null;
  profile?: ProfileSaveModel;
}

export interface ProfileImportDetailModelListResultHandler {
  succeeded?: boolean;
  data?: ProfileImportDetailModel[] | null;
  message?: string | null;
}

export interface ProfileSaveModel {
  profileId?: string | null;
  status?: ProfileStatusEnum;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  gender?: GenderEnum;
  companyId?: string | null;
  phone?: string | null;
  email?: string | null;
  holderId?: string | null;
  planCareURL?: string | null;
}

export interface ProfileSearchModel {
  profileId?: string | null;
  status?: ProfileStatusEnum;
  holderName?: string | null;
  name?: string | null;
  email?: string | null;
  companyName?: string | null;

  /** @format int32 */
  profileInvitedCount?: number;

  /** @format int32 */
  profileChildrenCount?: number;
  inviteCode?: string | null;
  resendInviteEmail?: boolean;

  /** @format date-time */
  lastAccess?: string | null;

  /** @format date-time */
  updated?: string | null;
  planCareURL?: string | null;
}

export interface ProfileSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ProfileSearchModel[] | null;
}

export interface ProfileSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ProfileSearchModelPaginationResult;
  message?: string | null;
}

export enum ProfileStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface ProfileStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: ProfileStatusEnum[] | null;
  message?: string | null;
}

export interface ReportCallModel {
  /** @format int32 */
  totalChatCalls?: number;

  /** @format int32 */
  totalChatPeople?: number;

  /** @format int32 */
  totalConsultationCalls?: number;

  /** @format int32 */
  totalConsultationPeople?: number;
}

export interface ReportCallModelResultHandler {
  succeeded?: boolean;
  data?: ReportCallModel;
  message?: string | null;
}

export interface ReportCompanyModel {
  /** @format int32 */
  totalActiveCompanyContracts?: number;

  /** @format int32 */
  totalOnTrialCompanyContracts?: number;

  /** @format int32 */
  totalInactiveCompanyContracts?: number;
}

export interface ReportCompanyModelResultHandler {
  succeeded?: boolean;
  data?: ReportCompanyModel;
  message?: string | null;
}

export interface ReportContentDetailModel {
  /** @format int32 */
  totalAccesses?: number;
  contentId?: string | null;
  type?: ContentTypeEnum;
  title?: string | null;
}

export interface ReportContentModel {
  themeName?: string | null;

  /** @format int32 */
  totalAccesses?: number;

  /** @format int32 */
  totalPeople?: number;
  topFiveContents?: ReportContentDetailModel[] | null;
}

export interface ReportContentModelListResultHandler {
  succeeded?: boolean;
  data?: ReportContentModel[] | null;
  message?: string | null;
}

export interface ReportContentMoreAccessModel {
  /** @format int32 */
  totalThemeMoreAccess?: number;

  /** @format int32 */
  totalThemePeople?: number;

  /** @format int32 */
  totalContentMoreAccess?: number;

  /** @format int32 */
  totalContentPeople?: number;

  /** @format int32 */
  totalContentPeopleAccess?: number;
}

export interface ReportContentMoreAccessModelResultHandler {
  succeeded?: boolean;
  data?: ReportContentMoreAccessModel;
  message?: string | null;
}

export interface ReportCriteria {
  /** @format date-time */
  dateStart?: string | null;

  /** @format date-time */
  dateEnd?: string | null;
  companyIds?: string[] | null;
  phaseTypes?: PhaseTypeModel[] | null;

  /** @format int32 */
  month?: number;

  /** @format int32 */
  year?: number;
}

export interface ReportProfileAccessModel {
  /** @format int32 */
  totalAccess?: number;

  /** @format int32 */
  totalPeople?: number;
}

export interface ReportProfileAccessModelResultHandler {
  succeeded?: boolean;
  data?: ReportProfileAccessModel;
  message?: string | null;
}

export interface ReportProfileModel {
  /** @format int32 */
  totalSubscribers?: number;

  /** @format int32 */
  totalActiveSubscribers?: number;

  /** @format int32 */
  totalDependents?: number;

  /** @format int32 */
  totalChildren?: number;

  /** @format int32 */
  totalLifesCared?: number;

  /** @format int32 */
  totalFamilies?: number;

  /** @format int32 */
  totalOnFamilyPlanning?: number;

  /** @format int32 */
  totalOnExpectingBaby?: number;

  /** @format int32 */
  totalOnRaisingChildren?: number;

  /** @format int32 */
  totalOnCaringForBaby?: number;
}

export interface ReportProfileModelResultHandler {
  succeeded?: boolean;
  data?: ReportProfileModel;
  message?: string | null;
}

export interface ResultHandler {
  succeeded?: boolean;
}

export interface SpecialistDetailModel {
  specialistId?: string | null;
  status?: SpecialistStatusEnum;
  type?: SpecialistTypeEnum;
  gender?: GenderEnum;
  firstName?: string | null;
  lastName?: string | null;
  specialization?: KeyValueModel;
  personalDocument?: DocumentModel;
  professionalDocument?: DocumentModel;
  shortBio?: string | null;
  treatment?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  email?: string | null;
  phone?: string | null;
  intercomId?: string | null;
  conexaId?: string | null;

  /** @format date-time */
  updated?: string | null;
  avatarMedia?: MediaModel;
  presentationMedia?: MediaModel;
  deepLinkingAddress?: string | null;
}

export interface SpecialistDetailModelResultHandler {
  succeeded?: boolean;
  data?: SpecialistDetailModel;
  message?: string | null;
}

export interface SpecialistSaveModel {
  specialistId?: string | null;
  status?: SpecialistStatusEnum;
  type?: SpecialistTypeEnum;
  gender?: GenderEnum;
  firstName?: string | null;
  lastName?: string | null;
  shortBio?: string | null;
  treatment?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  email?: string | null;
  phone?: string | null;
  personalDocument?: DocumentModel;
  professionalDocument?: DocumentModel;
  specializationId?: string | null;
  intercomId?: string | null;
  conexaId?: string | null;
  avatarMediaId?: string | null;
  presentationMediaId?: string | null;
}

export interface SpecialistSearchModel {
  specialistId?: string | null;
  fullName?: string | null;
  specializationName?: string | null;
  avatarMedia?: MediaModel;

  /** @format int32 */
  mediaCount?: number;
  intercomId?: string | null;
  conexaId?: string | null;

  /** @format date-time */
  updated?: string;
  deepLinkingAddress?: string | null;
}

export interface SpecialistSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: SpecialistSearchModel[] | null;
}

export interface SpecialistSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: SpecialistSearchModelPaginationResult;
  message?: string | null;
}

export enum SpecialistStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface SpecialistStatusEnumArrayResultHandler {
  succeeded?: boolean;
  data?: SpecialistStatusEnum[] | null;
  message?: string | null;
}

export enum SpecialistTypeEnum {
  Doctor = 'Doctor',
  Nurse = 'Nurse',
  HealthProfessional = 'HealthProfessional',
  Editor = 'Editor',
}

export interface SpecialistTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: SpecialistTypeEnum[] | null;
  message?: string | null;
}

export enum SpecializationTypeEnum {
  Generic = 'Generic',
  Occupation = 'Occupation',
  Conexa = 'Conexa',
}

export enum StateCodeEnum {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PA = 'PA',
  PB = 'PB',
  PR = 'PR',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SP = 'SP',
  SE = 'SE',
  TO = 'TO',
}

export interface StateCodeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: StateCodeEnum[] | null;
  message?: string | null;
}

export interface StatementModel {
  statementId?: string | null;
  contract?: ContractModel;
  account?: AccountModel;
  profile?: ProfileDetailModel;
  type?: StatementTypeEnum;

  /** @format int32 */
  value?: number;
  currency?: CurrencyEnum;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string | null;
}

export interface StatementModelPaginationResult {
  pagination?: PaginationDetail;
  result?: StatementModel[] | null;
}

export interface StatementModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: StatementModelPaginationResult;
  message?: string | null;
}

export interface StatementModelResultHandler {
  succeeded?: boolean;
  data?: StatementModel;
  message?: string | null;
}

export enum StatementTypeEnum {
  Credit = 'Credit',
  Debit = 'Debit',
  Expiration = 'Expiration',
}

export interface StatementTypeEnumArrayResultHandler {
  succeeded?: boolean;
  data?: StatementTypeEnum[] | null;
  message?: string | null;
}

export interface ThemeDetailModel {
  themeId?: string | null;
  name?: string | null;
  femaleMedia?: MediaModel;
  maleMedia?: MediaModel;
  anotherMedia?: MediaModel;
}

export interface ThemeDetailModelResultHandler {
  succeeded?: boolean;
  data?: ThemeDetailModel;
  message?: string | null;
}

export interface ThemeSaveModel {
  themeId?: string | null;
  name?: string | null;
  femaleMediaId?: string | null;
  maleMediaId?: string | null;
  anotherMediaId?: string | null;
}

export interface ThemeSearchModel {
  themeId?: string | null;
  name?: string | null;

  /** @format int32 */
  mediaCount?: number;

  /** @format date-time */
  updated?: string | null;
}

export interface ThemeSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ThemeSearchModel[] | null;
}

export interface ThemeSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ThemeSearchModelPaginationResult;
  message?: string | null;
}

export interface UserAdminDetailModel {
  userAdminId?: string | null;
  name?: string | null;
  email?: string | null;
}

export interface UserAdminDetailModelResultHandler {
  succeeded?: boolean;
  data?: UserAdminDetailModel;
  message?: string | null;
}

export interface UserAdminSaveModel {
  userAdminId?: string | null;
  name?: string | null;
  email?: string | null;
  password?: string | null;
}

export interface UserAdminSearchModel {
  userAdminId?: string | null;
  name?: string | null;
  email?: string | null;

  /** @format date-time */
  updated?: string;
}

export interface UserAdminSearchModelPaginationResult {
  pagination?: PaginationDetail;
  result?: UserAdminSearchModel[] | null;
}

export interface UserAdminSearchModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: UserAdminSearchModelPaginationResult;
  message?: string | null;
}

export interface AccountSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
}

export interface AccountDeleteParams {
  /** The identifier. */
  accountId?: string;
}

export interface CompanySearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Field?: string;
  Order?: PaginationDirection;
  Search?: string;
  Status?: CompanyStatusEnum;
}

export interface CompanyAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;

  /**
   * The status Id
   * @format int32
   */
  statusId?: number;
}

export interface CompanyDeleteParams {
  /** The identifier. */
  companyId?: string;
}

export interface ContentSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Field?: string;
  Order?: PaginationDirection;
  Title?: string;
  Status?: ContentStatusEnum;
  Type?: ContentTypeEnum;
  ThemeId?: string;
  PhaseId?: string;
  SpecialistId?: string;
  PersonaId?: string;
}

export interface ContentAutocompleteListParams {
  /** The title. */
  title?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface ContentDeleteParams {
  /** The identifier. */
  contentId?: string;
}

export interface ContractSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
  Status?: ContractStatusEnum;
  Type?: ContractTypeEnum;
}

export interface ContractDeleteParams {
  /** The identifier. */
  contractId?: string;
}

export interface ContractProfileSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
  Status?: ContractProfileStatusEnum;
  CompanyId?: string;
  Gender?: GenderEnum;

  /** @format int32 */
  AgeStart?: number;

  /** @format int32 */
  AgeEnd?: number;

  /** @format int32 */
  ProfileCount?: number;

  /** @format int32 */
  ChildrenCount?: number;

  /** @format int32 */
  LastAccessRange?: number;

  /** @format int32 */
  Balance?: number;
}

export interface ContractProfileInviteSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
  Status?: ContractProfileStatusEnum;
  CompanyId?: string;
  Gender?: GenderEnum;

  /** @format int32 */
  AgeStart?: number;

  /** @format int32 */
  AgeEnd?: number;

  /** @format int32 */
  ProfileCount?: number;

  /** @format int32 */
  ChildrenCount?: number;

  /** @format int32 */
  LastAccessRange?: number;

  /** @format int32 */
  Balance?: number;
}

export interface ContractProfileDeleteParams {
  /** The identifier. */
  contractProfileId?: string;
}

export interface MediaSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface MediaUploadCreateParams {
  /** The type. */
  type?: MediaTypeEnum;
}

export interface NotificationSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
  Status?: NotificationStatusEnum;
  Type?: NotificationTypeEnum;
  CompanyId?: string;
  PhaseTypeId?: string;
  ThemeId?: string;

  /** @format int32 */
  LastAccesses?: number;
}

export interface NotificationDeleteParams {
  /** The identifier. */
  notificationId?: string;
}

export interface PersonaSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Name?: string;
}

export interface PersonaAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface PersonaDeleteParams {
  /** The identifier. */
  personaId?: string;
}

export interface PhaseSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Name?: string;
  Type?: string;
}

export interface PhaseAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface PhaseDeleteParams {
  /** The identifier. */
  phaseId?: string;
}

export interface ProfileSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Field?: string;
  Order?: PaginationDirection;
  Name?: string;
  Dependents?: boolean;
  Status?: ProfileStatusEnum;
  CompanyId?: string;
  Gender?: GenderEnum;

  /** @format int32 */
  AgeStart?: number;

  /** @format int32 */
  AgeEnd?: number;
  HasProfiles?: boolean;
  HasChildren?: boolean;

  /** @format int32 */
  LastAccessRange?: number;
  PhaseId?: string;

  /** @format int32 */
  Balance?: number;
}

export interface ProfileExportListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Field?: string;
  Order?: PaginationDirection;
  Name?: string;
  Dependents?: boolean;
  Status?: ProfileStatusEnum;
  CompanyId?: string;
  Gender?: GenderEnum;

  /** @format int32 */
  AgeStart?: number;

  /** @format int32 */
  AgeEnd?: number;
  HasProfiles?: boolean;
  HasChildren?: boolean;

  /** @format int32 */
  LastAccessRange?: number;
  PhaseId?: string;

  /** @format int32 */
  Balance?: number;
}

export interface ProfileDeleteParams {
  /** The profile identifier. */
  profileId?: string;
}

export interface ProfileInactivePartialUpdateParams {
  /** The profile identifier. */
  profileId?: string;

  /** The reason identifier. */
  reason?: string;
}

export interface ProfileSendInviteCreateParams {
  /** The profile identifier. */
  profileId?: string;
}

export interface ProfileActivePartialUpdateParams {
  /** The profile identifier. */
  profileId?: string;
}

export interface SpecialistSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Name?: string;
  Type?: SpecialistTypeEnum;
}

export interface SpecialistAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface SpecialistDeleteParams {
  /** The identifier. */
  specialistId?: string;
}

export interface SpecialistSpecializationAutocompleteListParams {
  /** The type. */
  type?: SpecializationTypeEnum;

  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface StatementSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Search?: string;
}

export interface ThemeSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Name?: string;
}

export interface ThemeAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface ThemeDeleteParams {
  /** The identifier. */
  themeId?: string;
}

export interface UserSearchListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface UserAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface UserDeleteParams {
  /** The identifier. */
  userAdminId?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Bloom - Backoffice API
 * @version v1
 *
 * Representational state transfer with HTTP methods (e.g., GET, POST, PUT, PATCH and DELETE)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Account
     * @name AccountSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/account/search
     * @response `200` `AccountModelPaginationResultResultHandler` Success
     */
    accountSearchList: (query: AccountSearchListParams, params: RequestParams = {}) =>
      this.request<AccountModelPaginationResultResultHandler, any>({
        path: `/api/account/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountDetail
     * @summary Details the specified account.
     * @request GET:/api/account/{accountId}
     * @response `200` `AccountModelResultHandler` Success
     */
    accountDetail: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountModelResultHandler, any>({
        path: `/api/account/${accountId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountCreate
     * @summary Creates the specified account.
     * @request POST:/api/account
     * @response `200` `AccountModelResultHandler` Success
     */
    accountCreate: (data: AccountModel, params: RequestParams = {}) =>
      this.request<AccountModelResultHandler, any>({
        path: `/api/account`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountUpdate
     * @summary Edits the specified account.
     * @request PUT:/api/account
     * @response `200` `AccountModelResultHandler` Success
     */
    accountUpdate: (data: AccountModel, params: RequestParams = {}) =>
      this.request<AccountModelResultHandler, any>({
        path: `/api/account`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountDelete
     * @summary Removes the specified account.
     * @request DELETE:/api/account
     * @response `200` `AccountModelResultHandler` Success
     */
    accountDelete: (query: AccountDeleteParams, params: RequestParams = {}) =>
      this.request<AccountModelResultHandler, any>({
        path: `/api/account`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name AccountStatusList
     * @summary Gets the status.
     * @request GET:/api/account/status
     * @response `200` `AccountStatusEnumArrayResultHandler` Success
     */
    accountStatusList: (params: RequestParams = {}) =>
      this.request<AccountStatusEnumArrayResultHandler, any>({
        path: `/api/account/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanySearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/company/search
     * @response `200` `CompanySearchModelPaginationResultResultHandler` Success
     */
    companySearchList: (query: CompanySearchListParams, params: RequestParams = {}) =>
      this.request<CompanySearchModelPaginationResultResultHandler, any>({
        path: `/api/company/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/company/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    companyAutocompleteList: (query: CompanyAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/company/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDetail
     * @summary Details the specified company.
     * @request GET:/api/company/{companyId}
     * @response `200` `CompanyDetailModelResultHandler` Success
     */
    companyDetail: (companyId: string, params: RequestParams = {}) =>
      this.request<CompanyDetailModelResultHandler, any>({
        path: `/api/company/${companyId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyCreate
     * @summary Creates the specified company.
     * @request POST:/api/company
     * @response `200` `CompanyDetailModelResultHandler` Success
     */
    companyCreate: (data: CompanySaveModel, params: RequestParams = {}) =>
      this.request<CompanyDetailModelResultHandler, any>({
        path: `/api/company`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdate
     * @summary Edits the specified company.
     * @request PUT:/api/company
     * @response `200` `CompanyDetailModelResultHandler` Success
     */
    companyUpdate: (data: CompanySaveModel, params: RequestParams = {}) =>
      this.request<CompanyDetailModelResultHandler, any>({
        path: `/api/company`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDelete
     * @summary Removes the specified company.
     * @request DELETE:/api/company
     * @response `200` `CompanyDetailModelResultHandler` Success
     */
    companyDelete: (query: CompanyDeleteParams, params: RequestParams = {}) =>
      this.request<CompanyDetailModelResultHandler, any>({
        path: `/api/company`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyStatusList
     * @summary Gets the status.
     * @request GET:/api/company/status
     * @response `200` `CompanyStatusEnumArrayResultHandler` Success
     */
    companyStatusList: (params: RequestParams = {}) =>
      this.request<CompanyStatusEnumArrayResultHandler, any>({
        path: `/api/company/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/content/search
     * @response `200` `ContentSearchModelPaginationResultResultHandler` Success
     */
    contentSearchList: (query: ContentSearchListParams, params: RequestParams = {}) =>
      this.request<ContentSearchModelPaginationResultResultHandler, any>({
        path: `/api/content/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/content/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    contentAutocompleteList: (query: ContentAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/content/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentDetail
     * @summary Details the specified content.
     * @request GET:/api/content/{contentId}
     * @response `200` `ContentDetailModelResultHandler` Success
     */
    contentDetail: (contentId: string, params: RequestParams = {}) =>
      this.request<ContentDetailModelResultHandler, any>({
        path: `/api/content/${contentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentCreate
     * @summary Creates the specified content.
     * @request POST:/api/content
     * @response `200` `ContentDetailModelResultHandler` Success
     */
    contentCreate: (data: ContentSaveModel, params: RequestParams = {}) =>
      this.request<ContentDetailModelResultHandler, any>({
        path: `/api/content`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentUpdate
     * @summary Edits the specified content.
     * @request PUT:/api/content
     * @response `200` `ContentDetailModelResultHandler` Success
     */
    contentUpdate: (data: ContentSaveModel, params: RequestParams = {}) =>
      this.request<ContentDetailModelResultHandler, any>({
        path: `/api/content`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentDelete
     * @summary Removes the specified content.
     * @request DELETE:/api/content
     * @response `200` `ResultHandler` Success
     */
    contentDelete: (query: ContentDeleteParams, params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/content`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentStatusList
     * @summary Gets the status.
     * @request GET:/api/content/status
     * @response `200` `ContentStatusEnumArrayResultHandler` Success
     */
    contentStatusList: (params: RequestParams = {}) =>
      this.request<ContentStatusEnumArrayResultHandler, any>({
        path: `/api/content/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name ContentTypeList
     * @summary Gets the types.
     * @request GET:/api/content/type
     * @response `200` `ContentTypeEnumArrayResultHandler` Success
     */
    contentTypeList: (params: RequestParams = {}) =>
      this.request<ContentTypeEnumArrayResultHandler, any>({
        path: `/api/content/type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/contract/search
     * @response `200` `ContractModelPaginationResultResultHandler` Success
     */
    contractSearchList: (query: ContractSearchListParams, params: RequestParams = {}) =>
      this.request<ContractModelPaginationResultResultHandler, any>({
        path: `/api/contract/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractDetail
     * @summary Details the specified contract.
     * @request GET:/api/contract/{contractId}
     * @response `200` `ContractModelResultHandler` Success
     */
    contractDetail: (contractId: string, params: RequestParams = {}) =>
      this.request<ContractModelResultHandler, any>({
        path: `/api/contract/${contractId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractCreate
     * @summary Creates the specified contract.
     * @request POST:/api/contract
     * @response `200` `ContractModelResultHandler` Success
     */
    contractCreate: (data: ContractModel, params: RequestParams = {}) =>
      this.request<ContractModelResultHandler, any>({
        path: `/api/contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractUpdate
     * @summary Edits the specified contract.
     * @request PUT:/api/contract
     * @response `200` `ContractModelResultHandler` Success
     */
    contractUpdate: (data: ContractModel, params: RequestParams = {}) =>
      this.request<ContractModelResultHandler, any>({
        path: `/api/contract`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractDelete
     * @summary Removes the specified contract.
     * @request DELETE:/api/contract
     * @response `200` `ContractModelResultHandler` Success
     */
    contractDelete: (query: ContractDeleteParams, params: RequestParams = {}) =>
      this.request<ContractModelResultHandler, any>({
        path: `/api/contract`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractStatusList
     * @summary Gets the status.
     * @request GET:/api/contract/status
     * @response `200` `ContractStatusEnumArrayResultHandler` Success
     */
    contractStatusList: (params: RequestParams = {}) =>
      this.request<ContractStatusEnumArrayResultHandler, any>({
        path: `/api/contract/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractTypeList
     * @summary Gets the types.
     * @request GET:/api/contract/type
     * @response `200` `ContractTypeEnumArrayResultHandler` Success
     */
    contractTypeList: (params: RequestParams = {}) =>
      this.request<ContractTypeEnumArrayResultHandler, any>({
        path: `/api/contract/type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileSearchList
     * @summary Searches by specified profile criteria.
     * @request GET:/api/contract/profile/search
     * @response `200` `ContractProfileSearchModelPaginationResultResultHandler` Success
     */
    contractProfileSearchList: (
      query: ContractProfileSearchListParams,
      params: RequestParams = {}
    ) =>
      this.request<ContractProfileSearchModelPaginationResultResultHandler, any>({
        path: `/api/contract/profile/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileInviteSearchList
     * @summary Searches by specified profile criteria.
     * @request GET:/api/contract/profile/invite/search
     * @response `200` `InviteSearchModelPaginationResultResultHandler` Success
     */
    contractProfileInviteSearchList: (
      query: ContractProfileInviteSearchListParams,
      params: RequestParams = {}
    ) =>
      this.request<InviteSearchModelPaginationResultResultHandler, any>({
        path: `/api/contract/profile/invite/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileDetail
     * @summary Details the specified contract profile.
     * @request GET:/api/contract/profile/{contractProfileId}
     * @response `200` `ContractProfileDetailModelResultHandler` Success
     */
    contractProfileDetail: (contractProfileId: string, params: RequestParams = {}) =>
      this.request<ContractProfileDetailModelResultHandler, any>({
        path: `/api/contract/profile/${contractProfileId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileCreate
     * @summary Creates the specified contract profile.
     * @request POST:/api/contract/profile
     * @response `200` `ContractProfileDetailModelResultHandler` Success
     */
    contractProfileCreate: (data: ContractProfileSaveModel, params: RequestParams = {}) =>
      this.request<ContractProfileDetailModelResultHandler, any>({
        path: `/api/contract/profile`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileUpdate
     * @summary Edits the specified contract profile.
     * @request PUT:/api/contract/profile
     * @response `200` `ContractProfileDetailModelResultHandler` Success
     */
    contractProfileUpdate: (data: ContractProfileSaveModel, params: RequestParams = {}) =>
      this.request<ContractProfileDetailModelResultHandler, any>({
        path: `/api/contract/profile`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileDelete
     * @summary Removes the specified contract profile.
     * @request DELETE:/api/contract/profile
     * @response `200` `ContractProfileDetailModelResultHandler` Success
     */
    contractProfileDelete: (query: ContractProfileDeleteParams, params: RequestParams = {}) =>
      this.request<ContractProfileDetailModelResultHandler, any>({
        path: `/api/contract/profile`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractProfileStatusList
     * @summary Gets the profile status.
     * @request GET:/api/contract/profile/status
     * @response `200` `ContractProfileStatusEnumArrayResultHandler` Success
     */
    contractProfileStatusList: (params: RequestParams = {}) =>
      this.request<ContractProfileStatusEnumArrayResultHandler, any>({
        path: `/api/contract/profile/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currency
     * @name CurrencyList
     * @summary Gets this instance.
     * @request GET:/api/currency
     * @response `200` `CurrencyEnumArrayResultHandler` Success
     */
    currencyList: (params: RequestParams = {}) =>
      this.request<CurrencyEnumArrayResultHandler, any>({
        path: `/api/currency`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardCompanyList
     * @summary Searches company report.
     * @request GET:/api/dashboard/company
     * @response `200` `ReportCompanyModelResultHandler` Success
     */
    dashboardCompanyList: (params: RequestParams = {}) =>
      this.request<ReportCompanyModelResultHandler, any>({
        path: `/api/dashboard/company`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardContentCreate
     * @summary Searches content report by specified criteria.
     * @request POST:/api/dashboard/content
     * @response `200` `ReportContentModelListResultHandler` Success
     */
    dashboardContentCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<ReportContentModelListResultHandler, any>({
        path: `/api/dashboard/content`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardContentMoreAccessCreate
     * @summary Searches content report by specified criteria.
     * @request POST:/api/dashboard/content/more-access
     * @response `200` `ReportContentMoreAccessModelResultHandler` Success
     */
    dashboardContentMoreAccessCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<ReportContentMoreAccessModelResultHandler, any>({
        path: `/api/dashboard/content/more-access`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardContentMoreAccessExportCreate
     * @summary Generate excel file by content report by specified criteria.
     * @request POST:/api/dashboard/content/more-access/export
     * @response `200` `File` Success
     */
    dashboardContentMoreAccessExportCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/dashboard/content/more-access/export`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardProfileCreate
     * @summary Searches profile report by specified criteria.
     * @request POST:/api/dashboard/profile
     * @response `200` `ReportProfileModelResultHandler` Success
     */
    dashboardProfileCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<ReportProfileModelResultHandler, any>({
        path: `/api/dashboard/profile`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardProfileAccessCreate
     * @summary Searches profile access report by specified criteria.
     * @request POST:/api/dashboard/profile/access
     * @response `200` `ReportProfileAccessModelResultHandler` Success
     */
    dashboardProfileAccessCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<ReportProfileAccessModelResultHandler, any>({
        path: `/api/dashboard/profile/access`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardCallsCreate
     * @summary Searches calls report.
     * @request POST:/api/dashboard/calls
     * @response `200` `ReportCallModelResultHandler` Success
     */
    dashboardCallsCreate: (data: ReportCriteria, params: RequestParams = {}) =>
      this.request<ReportCallModelResultHandler, any>({
        path: `/api/dashboard/calls`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepLinking
     * @name DeepLinkingList
     * @summary Gets this instance.
     * @request GET:/api/deep-linking
     * @response `200` `DeepLinkingModelArrayResultHandler` Success
     */
    deepLinkingList: (params: RequestParams = {}) =>
      this.request<DeepLinkingModelArrayResultHandler, any>({
        path: `/api/deep-linking`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Gender
     * @name GenderList
     * @summary Gets this instance.
     * @request GET:/api/gender
     * @response `200` `GenderEnumArrayResultHandler` Success
     */
    genderList: (params: RequestParams = {}) =>
      this.request<GenderEnumArrayResultHandler, any>({
        path: `/api/gender`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobUpdateReportCompanyCreate
     * @summary Updates the report company.
     * @request POST:/api/job/update-report-company
     * @response `200` `ResultHandler` Success
     */
    jobUpdateReportCompanyCreate: (params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/job/update-report-company`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobUpdateReportContentCreate
     * @summary Updates the content of the report.
     * @request POST:/api/job/update-report-content
     * @response `200` `ResultHandler` Success
     */
    jobUpdateReportContentCreate: (params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/job/update-report-content`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobUpdateReportProfileCreate
     * @summary Updates the report profile.
     * @request POST:/api/job/update-report-profile
     * @response `200` `ResultHandler` Success
     */
    jobUpdateReportProfileCreate: (params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/job/update-report-profile`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobProcessNotificationCreate
     * @summary Updates the report profile.
     * @request POST:/api/job/process-notification
     * @response `200` `ResultHandler` Success
     */
    jobProcessNotificationCreate: (params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/job/process-notification`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name MediaSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/media/search
     * @response `200` `MediaModelPaginationResultResultHandler` Success
     */
    mediaSearchList: (query: MediaSearchListParams, params: RequestParams = {}) =>
      this.request<MediaModelPaginationResultResultHandler, any>({
        path: `/api/media/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name MediaUploadCreate
     * @summary Uploads the media.
     * @request POST:/api/media/upload
     * @response `200` `MediaModelResultHandler` Success
     */
    mediaUploadCreate: (
      query: MediaUploadCreateParams,
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MediaModelResultHandler, any>({
        path: `/api/media/upload`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name MediaCompressCreate
     * @summary Compresses the media.
     * @request POST:/api/media/compress
     * @response `200` `ResultHandler` Success
     */
    mediaCompressCreate: (data: number[], params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/media/compress`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaType
     * @name MediaTypeList
     * @summary Gets this instance.
     * @request GET:/api/media-type
     * @response `200` `MediaTypeEnumArrayResultHandler` Success
     */
    mediaTypeList: (params: RequestParams = {}) =>
      this.request<MediaTypeEnumArrayResultHandler, any>({
        path: `/api/media-type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/notification/search
     * @response `200` `NotificationSearchModelPaginationResultResultHandler` Success
     */
    notificationSearchList: (query: NotificationSearchListParams, params: RequestParams = {}) =>
      this.request<NotificationSearchModelPaginationResultResultHandler, any>({
        path: `/api/notification/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationReadyToSendList
     * @summary Get all notifications from ready to send.
     * @request GET:/api/notification/ready-to-send
     * @response `200` `NotificationAllModelListResultHandler` Success
     */
    notificationReadyToSendList: (params: RequestParams = {}) =>
      this.request<NotificationAllModelListResultHandler, any>({
        path: `/api/notification/ready-to-send`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationDetail
     * @summary Details the specified notification.
     * @request GET:/api/notification/{notificationId}
     * @response `200` `NotificationDetailModelResultHandler` Success
     */
    notificationDetail: (notificationId: string, params: RequestParams = {}) =>
      this.request<NotificationDetailModelResultHandler, any>({
        path: `/api/notification/${notificationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationCreate
     * @summary Creates the specified notification.
     * @request POST:/api/notification
     * @response `200` `NotificationDetailModelResultHandler` Success
     */
    notificationCreate: (data: NotificationSaveModel, params: RequestParams = {}) =>
      this.request<NotificationDetailModelResultHandler, any>({
        path: `/api/notification`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationUpdate
     * @summary Edits the specified notification.
     * @request PUT:/api/notification
     * @response `200` `NotificationDetailModelResultHandler` Success
     */
    notificationUpdate: (data: NotificationSaveModel, params: RequestParams = {}) =>
      this.request<NotificationDetailModelResultHandler, any>({
        path: `/api/notification`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationDelete
     * @summary Set the status canceled by specified notification.
     * @request DELETE:/api/notification
     * @response `200` `NotificationDetailModelResultHandler` Success
     */
    notificationDelete: (query: NotificationDeleteParams, params: RequestParams = {}) =>
      this.request<NotificationDetailModelResultHandler, any>({
        path: `/api/notification`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationStatusList
     * @summary Gets the status.
     * @request GET:/api/notification/status
     * @response `200` `NotificationStatusEnumArrayResultHandler` Success
     */
    notificationStatusList: (params: RequestParams = {}) =>
      this.request<NotificationStatusEnumArrayResultHandler, any>({
        path: `/api/notification/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationTypesList
     * @summary Gets the types.
     * @request GET:/api/notification/types
     * @response `200` `NotificationTypeEnumArrayResultHandler` Success
     */
    notificationTypesList: (params: RequestParams = {}) =>
      this.request<NotificationTypeEnumArrayResultHandler, any>({
        path: `/api/notification/types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/persona/search
     * @response `200` `PersonaModelPaginationResultResultHandler` Success
     */
    personaSearchList: (query: PersonaSearchListParams, params: RequestParams = {}) =>
      this.request<PersonaModelPaginationResultResultHandler, any>({
        path: `/api/persona/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/persona/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    personaAutocompleteList: (query: PersonaAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/persona/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaDetail
     * @summary Details the specified persona.
     * @request GET:/api/persona/{personaId}
     * @response `200` `PersonaModelResultHandler` Success
     */
    personaDetail: (personaId: string, params: RequestParams = {}) =>
      this.request<PersonaModelResultHandler, any>({
        path: `/api/persona/${personaId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaCreate
     * @summary Creates the specified persona.
     * @request POST:/api/persona
     * @response `200` `PersonaModelResultHandler` Success
     */
    personaCreate: (data: PersonaModel, params: RequestParams = {}) =>
      this.request<PersonaModelResultHandler, any>({
        path: `/api/persona`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaUpdate
     * @summary Edits the specified persona.
     * @request PUT:/api/persona
     * @response `200` `PersonaModelResultHandler` Success
     */
    personaUpdate: (data: PersonaModel, params: RequestParams = {}) =>
      this.request<PersonaModelResultHandler, any>({
        path: `/api/persona`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persona
     * @name PersonaDelete
     * @summary Removes the specified persona.
     * @request DELETE:/api/persona
     * @response `200` `PersonaModelResultHandler` Success
     */
    personaDelete: (query: PersonaDeleteParams, params: RequestParams = {}) =>
      this.request<PersonaModelResultHandler, any>({
        path: `/api/persona`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/phase/search
     * @response `200` `PhaseSearchModelPaginationResultResultHandler` Success
     */
    phaseSearchList: (query: PhaseSearchListParams, params: RequestParams = {}) =>
      this.request<PhaseSearchModelPaginationResultResultHandler, any>({
        path: `/api/phase/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/phase/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    phaseAutocompleteList: (query: PhaseAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/phase/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseDetail
     * @summary Details the specified phase.
     * @request GET:/api/phase/{phaseId}
     * @response `200` `PhaseDetailModelResultHandler` Success
     */
    phaseDetail: (phaseId: string, params: RequestParams = {}) =>
      this.request<PhaseDetailModelResultHandler, any>({
        path: `/api/phase/${phaseId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseCreate
     * @summary Creates the specified phase.
     * @request POST:/api/phase
     * @response `200` `PhaseDetailModelResultHandler` Success
     */
    phaseCreate: (data: PhaseSaveModel, params: RequestParams = {}) =>
      this.request<PhaseDetailModelResultHandler, any>({
        path: `/api/phase`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseUpdate
     * @summary Edits the specified phase.
     * @request PUT:/api/phase
     * @response `200` `PhaseDetailModelResultHandler` Success
     */
    phaseUpdate: (data: PhaseSaveModel, params: RequestParams = {}) =>
      this.request<PhaseDetailModelResultHandler, any>({
        path: `/api/phase`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseDelete
     * @summary Removes the specified phase.
     * @request DELETE:/api/phase
     * @response `200` `ResultHandler` Success
     */
    phaseDelete: (query: PhaseDeleteParams, params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/phase`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phase
     * @name PhaseTypeList
     * @summary Gets the types.
     * @request GET:/api/phase/type
     * @response `200` `PhaseTypeModelArrayResultHandler` Success
     */
    phaseTypeList: (params: RequestParams = {}) =>
      this.request<PhaseTypeModelArrayResultHandler, any>({
        path: `/api/phase/type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/profile/search
     * @response `200` `ProfileSearchModelPaginationResultResultHandler` Success
     */
    profileSearchList: (query: ProfileSearchListParams, params: RequestParams = {}) =>
      this.request<ProfileSearchModelPaginationResultResultHandler, any>({
        path: `/api/profile/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileAutocompleteCreate
     * @summary Autocomplete the specified data.
     * @request POST:/api/profile/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    profileAutocompleteCreate: (data: AutocompleteCriteria, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/profile/autocomplete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileExportList
     * @summary Subscribers the export search.
     * @request GET:/api/profile/export
     * @response `200` `File` Success
     */
    profileExportList: (query: ProfileExportListParams, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/profile/export`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileDetail
     * @summary Details the specified profile.
     * @request GET:/api/profile/{subscriberId}
     * @response `200` `ProfileDetailModelResultHandler` Success
     */
    profileDetail: (subscriberId: string, params: RequestParams = {}) =>
      this.request<ProfileDetailModelResultHandler, any>({
        path: `/api/profile/${subscriberId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileCreate
     * @summary Creates the specified profile.
     * @request POST:/api/profile
     * @response `200` `ProfileDetailModelResultHandler` Success
     */
    profileCreate: (data: ProfileSaveModel, params: RequestParams = {}) =>
      this.request<ProfileDetailModelResultHandler, any>({
        path: `/api/profile`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileUpdate
     * @summary Edits the specified profile.
     * @request PUT:/api/profile
     * @response `200` `ProfileDetailModelResultHandler` Success
     */
    profileUpdate: (data: ProfileSaveModel, params: RequestParams = {}) =>
      this.request<ProfileDetailModelResultHandler, any>({
        path: `/api/profile`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileDelete
     * @summary Removes the specified profile identifier.
     * @request DELETE:/api/profile
     * @response `200` `ResultHandler` Success
     */
    profileDelete: (query: ProfileDeleteParams, params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/profile`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileInactivePartialUpdate
     * @summary Inactive the specified profile identifier.
     * @request PATCH:/api/profile/inactive
     * @response `200` `ResultHandler` Success
     */
    profileInactivePartialUpdate: (
      query: ProfileInactivePartialUpdateParams,
      params: RequestParams = {}
    ) =>
      this.request<ResultHandler, any>({
        path: `/api/profile/inactive`,
        method: 'PATCH',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileSendInviteCreate
     * @summary Inactive the specified profile identifier.
     * @request POST:/api/profile/send-invite
     * @response `200` `ResultHandler` Success
     */
    profileSendInviteCreate: (query: ProfileSendInviteCreateParams, params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/profile/send-invite`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileActivePartialUpdate
     * @summary Active the specified profile identifier.
     * @request PATCH:/api/profile/active
     * @response `200` `ResultHandler` Success
     */
    profileActivePartialUpdate: (
      query: ProfileActivePartialUpdateParams,
      params: RequestParams = {}
    ) =>
      this.request<ResultHandler, any>({
        path: `/api/profile/active`,
        method: 'PATCH',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileStatusList
     * @summary Gets the status.
     * @request GET:/api/profile/status
     * @response `200` `ProfileStatusEnumArrayResultHandler` Success
     */
    profileStatusList: (params: RequestParams = {}) =>
      this.request<ProfileStatusEnumArrayResultHandler, any>({
        path: `/api/profile/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileImportCreate
     * @summary Import file.
     * @request POST:/api/profile/import
     * @response `200` `ProfileImportDetailModelListResultHandler` Success
     */
    profileImportCreate: (data: ProfileImportCriteria, params: RequestParams = {}) =>
      this.request<ProfileImportDetailModelListResultHandler, any>({
        path: `/api/profile/import`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name PublicContentDetail
     * @summary Details the specified content.
     * @request GET:/api/public/content/{contentId}
     * @response `200` `ContentDetailModelResultHandler` Success
     */
    publicContentDetail: (contentId: number, params: RequestParams = {}) =>
      this.request<ContentDetailModelResultHandler, any>({
        path: `/api/public/content/${contentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name PublicSpecialistDetail
     * @summary Details the specified specialist.
     * @request GET:/api/public/specialist/{specialistId}
     * @response `200` `SpecialistDetailModelResultHandler` Success
     */
    publicSpecialistDetail: (specialistId: string, params: RequestParams = {}) =>
      this.request<SpecialistDetailModelResultHandler, any>({
        path: `/api/public/specialist/${specialistId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reason
     * @name ReasonList
     * @summary Gets this instance.
     * @request GET:/api/reason
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    reasonList: (params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/reason`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/specialist/search
     * @response `200` `SpecialistSearchModelPaginationResultResultHandler` Success
     */
    specialistSearchList: (query: SpecialistSearchListParams, params: RequestParams = {}) =>
      this.request<SpecialistSearchModelPaginationResultResultHandler, any>({
        path: `/api/specialist/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/specialist/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    specialistAutocompleteList: (
      query: SpecialistAutocompleteListParams,
      params: RequestParams = {}
    ) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/specialist/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistDetail
     * @summary Details the specified specialist.
     * @request GET:/api/specialist/{specialistId}
     * @response `200` `SpecialistDetailModelResultHandler` Success
     */
    specialistDetail: (specialistId: string, params: RequestParams = {}) =>
      this.request<SpecialistDetailModelResultHandler, any>({
        path: `/api/specialist/${specialistId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistCreate
     * @summary Creates the specified specialist.
     * @request POST:/api/specialist
     * @response `200` `SpecialistDetailModelResultHandler` Success
     */
    specialistCreate: (data: SpecialistSaveModel, params: RequestParams = {}) =>
      this.request<SpecialistDetailModelResultHandler, any>({
        path: `/api/specialist`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistUpdate
     * @summary Edits the specified specialist.
     * @request PUT:/api/specialist
     * @response `200` `SpecialistDetailModelResultHandler` Success
     */
    specialistUpdate: (data: SpecialistSaveModel, params: RequestParams = {}) =>
      this.request<SpecialistDetailModelResultHandler, any>({
        path: `/api/specialist`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistDelete
     * @summary Removes the specified specialist.
     * @request DELETE:/api/specialist
     * @response `200` `SpecialistDetailModelResultHandler` Success
     */
    specialistDelete: (query: SpecialistDeleteParams, params: RequestParams = {}) =>
      this.request<SpecialistDetailModelResultHandler, any>({
        path: `/api/specialist`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistStatusList
     * @summary Gets this instance.
     * @request GET:/api/specialist/status
     * @response `200` `SpecialistStatusEnumArrayResultHandler` Success
     */
    specialistStatusList: (params: RequestParams = {}) =>
      this.request<SpecialistStatusEnumArrayResultHandler, any>({
        path: `/api/specialist/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistTypeList
     * @summary Gets the types.
     * @request GET:/api/specialist/type
     * @response `200` `SpecialistTypeEnumArrayResultHandler` Success
     */
    specialistTypeList: (params: RequestParams = {}) =>
      this.request<SpecialistTypeEnumArrayResultHandler, any>({
        path: `/api/specialist/type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Specialist
     * @name SpecialistSpecializationAutocompleteList
     * @summary Specializations the autocomplete.
     * @request GET:/api/specialist/specialization/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    specialistSpecializationAutocompleteList: (
      query: SpecialistSpecializationAutocompleteListParams,
      params: RequestParams = {}
    ) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/specialist/specialization/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags State
     * @name StateList
     * @summary Gets this instance.
     * @request GET:/api/state
     * @response `200` `StateCodeEnumArrayResultHandler` Success
     */
    stateList: (params: RequestParams = {}) =>
      this.request<StateCodeEnumArrayResultHandler, any>({
        path: `/api/state`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statement
     * @name StatementSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/statement/search
     * @response `200` `StatementModelPaginationResultResultHandler` Success
     */
    statementSearchList: (query: StatementSearchListParams, params: RequestParams = {}) =>
      this.request<StatementModelPaginationResultResultHandler, any>({
        path: `/api/statement/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statement
     * @name StatementDetail
     * @summary Details the specified statement.
     * @request GET:/api/statement/{statementId}
     * @response `200` `StatementModelResultHandler` Success
     */
    statementDetail: (statementId: string, params: RequestParams = {}) =>
      this.request<StatementModelResultHandler, any>({
        path: `/api/statement/${statementId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statement
     * @name StatementCreate
     * @summary Creates the specified statement.
     * @request POST:/api/statement
     * @response `200` `StatementModelResultHandler` Success
     */
    statementCreate: (data: StatementModel, params: RequestParams = {}) =>
      this.request<StatementModelResultHandler, any>({
        path: `/api/statement`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statement
     * @name StatementTypeList
     * @summary Gets the types.
     * @request GET:/api/statement/type
     * @response `200` `StatementTypeEnumArrayResultHandler` Success
     */
    statementTypeList: (params: RequestParams = {}) =>
      this.request<StatementTypeEnumArrayResultHandler, any>({
        path: `/api/statement/type`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/theme/search
     * @response `200` `ThemeSearchModelPaginationResultResultHandler` Success
     */
    themeSearchList: (query: ThemeSearchListParams, params: RequestParams = {}) =>
      this.request<ThemeSearchModelPaginationResultResultHandler, any>({
        path: `/api/theme/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/theme/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    themeAutocompleteList: (query: ThemeAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/theme/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeDetail
     * @summary Details the specified theme.
     * @request GET:/api/theme/{themeId}
     * @response `200` `ThemeDetailModelResultHandler` Success
     */
    themeDetail: (themeId: string, params: RequestParams = {}) =>
      this.request<ThemeDetailModelResultHandler, any>({
        path: `/api/theme/${themeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeCreate
     * @summary Creates the specified theme.
     * @request POST:/api/theme
     * @response `200` `ThemeDetailModelResultHandler` Success
     */
    themeCreate: (data: ThemeSaveModel, params: RequestParams = {}) =>
      this.request<ThemeDetailModelResultHandler, any>({
        path: `/api/theme`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeUpdate
     * @summary Edits the specified theme.
     * @request PUT:/api/theme
     * @response `200` `ThemeDetailModelResultHandler` Success
     */
    themeUpdate: (data: ThemeSaveModel, params: RequestParams = {}) =>
      this.request<ThemeDetailModelResultHandler, any>({
        path: `/api/theme`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Theme
     * @name ThemeDelete
     * @summary Removes the specified theme.
     * @request DELETE:/api/theme
     * @response `200` `ResultHandler` Success
     */
    themeDelete: (query: ThemeDeleteParams, params: RequestParams = {}) =>
      this.request<ResultHandler, any>({
        path: `/api/theme`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserSearchList
     * @summary Searches by specified criteria.
     * @request GET:/api/user/search
     * @response `200` `UserAdminSearchModelPaginationResultResultHandler` Success
     */
    userSearchList: (query: UserSearchListParams, params: RequestParams = {}) =>
      this.request<UserAdminSearchModelPaginationResultResultHandler, any>({
        path: `/api/user/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserMeList
     * @summary Get current user data
     * @request GET:/api/user/me
     * @response `200` `UserAdminDetailModelResultHandler` Success
     */
    userMeList: (params: RequestParams = {}) =>
      this.request<UserAdminDetailModelResultHandler, any>({
        path: `/api/user/me`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserAutocompleteList
     * @summary Autocomplete the specified data.
     * @request GET:/api/user/autocomplete
     * @response `200` `KeyValueModelArrayResultHandler` Success
     */
    userAutocompleteList: (query: UserAutocompleteListParams, params: RequestParams = {}) =>
      this.request<KeyValueModelArrayResultHandler, any>({
        path: `/api/user/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserDetail
     * @summary Details the specified userAdmin.
     * @request GET:/api/user/{userAdminId}
     * @response `200` `UserAdminDetailModelResultHandler` Success
     */
    userDetail: (userAdminId: string, params: RequestParams = {}) =>
      this.request<UserAdminDetailModelResultHandler, any>({
        path: `/api/user/${userAdminId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserCreate
     * @summary Creates the specified userAdmin.
     * @request POST:/api/user
     * @response `200` `UserAdminDetailModelResultHandler` Success
     */
    userCreate: (data: UserAdminSaveModel, params: RequestParams = {}) =>
      this.request<UserAdminDetailModelResultHandler, any>({
        path: `/api/user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserUpdate
     * @summary Edits the specified userAdmin.
     * @request PUT:/api/user
     * @response `200` `UserAdminDetailModelResultHandler` Success
     */
    userUpdate: (data: UserAdminSaveModel, params: RequestParams = {}) =>
      this.request<UserAdminDetailModelResultHandler, any>({
        path: `/api/user`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAdmin
     * @name UserDelete
     * @summary Removes the specified userAdmin.
     * @request DELETE:/api/user
     * @response `200` `UserAdminDetailModelResultHandler` Success
     */
    userDelete: (query: UserDeleteParams, params: RequestParams = {}) =>
      this.request<UserAdminDetailModelResultHandler, any>({
        path: `/api/user`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),
  };
}
